<template>
  <div>
    <SuccessButton
      class="mx-2"
      :disabled="!$atividades.permissoes.includes(14)"
      @func="openModal()"
    ></SuccessButton>
    <SideBar
      v-if="open"
      title="Cadastrar Local de Armazenamento"
      @hide="modalCadastrar = false"
      :active="modalCadastrar"
      icon="edit"
      size="small"
      @success="submitForm()"
    >
      <div slot="body" class="mb-4">
        <div id="loadingCadastrar" class="vs-con-loading__container">
          <vs-row class="mt-2" vs-type="flex" vs-w="12">
            <vs-col vs-w="12">
              <vs-input
                class="inputx w-full"
                name="nome"
                v-validate="'required'"
                label-placeholder="Nome"
                v-model="armazenamento.nome"
              />
              <span class="text-danger text-sm" v-show="errors.has('nome')">{{
                $validators.empty
              }}</span>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </SideBar>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  data() {
    return {
      modalCadastrar: false,
      open: false,
      armazenamento: {}
    };
  },
  methods: {
    async openModal() {
      this.armazenamento = {}
      this.open = await true;
      this.modalCadastrar = await true;
    },
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.cadastrar();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async cadastrar() {
      await this.$vs.loading({
        container: "#loadingCadastrar",
        scale: 0.6
      });
      try {
        const res = await this.$http.post(
          `armazenamento`,
          this.armazenamento
        );
        this.$vs.notify({
          title: "Sucesso",
          text: "Operação realizada com sucesso!",
          color: "success"
        });
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "cadastrar",
        //   setor: "pco",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Cadastro do local de armazenamento N°" + res.id
        // };
        // await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.modalCadastrar = await false;
        await this.$vs.loading.close(
          "#loadingCadastrar > .con-vs-loading"
        );
        await this.$emit('update')
      }
    },
  },
  components: {
    ...components
  }
};
</script>
