<template>
  <div class="w-full">
    <div>
      <vs-row
        vs-type="flex"
        class="mb-8 my-4"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col vs-w="6" vs-justify="flex-start" vs-align="flex-start">
          <h4 style="color: #b4aa99">Locais de Armazenamento</h4>
        </vs-col>
        <vs-col vs-w="6" vs-justify="flex-start" vs-align="flex-start">
          <Cadastrar @update="getArmazenamentos" />
        </vs-col>
      </vs-row>
    </div>
    <div id="loadingArmazenamento" class="vs-con-loading__container">
      <div class="my-10 py-10" v-if="!armazenamentos.length > 0">
        <div class="con-colors my-10 py-10">
          <ul>
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">
                Nenhum Local de Armazenamento
              </h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <vs-table
          noDataText=""
          :pagination="true"
          maxHeight="68vh"
          max-items="10"
          :data="armazenamentos"
        >
          <template slot="thead">
            <vs-th sort-key="armazenamento">Armazenamento</vs-th>
            <vs-th style="width: 5%">Ações</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :state="tr.lixeira == 1 ? 'danger' : ''"
              :key="index"
              v-for="(tr, index) in data"
            >
              <vs-td :data="tr.id">{{ tr.nome }}</vs-td>
              <vs-td>
                <Editar :item="tr" @update="getArmazenamentos" />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>
<script>
import Cadastrar from "./components/cadastrar"
import Editar from "./components/editar"
export default {
  data () {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      armazenamentos: {}
    }
  },
  methods: {
    async getArmazenamentos () {
      await this.$vs.loading({
        container: "#loadingArmazenamento",
        scale: 0.6
      })
      try {
        this.armazenamentos = await this.$http.get(`armazenamento`)
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      } finally {
        await this.$vs.loading.close("#loadingArmazenamento > .con-vs-loading")
      }
    }
  },
  async mounted () {
    await this.getArmazenamentos()
  },
  components: {
    Cadastrar, Editar
  }
};
</script>
