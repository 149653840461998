<template>
  <div>
    <EditButton
      class="mx-2"
      :disabled="!$atividades.permissoes.includes(14)"
      @func="openModal()"
    ></EditButton>
    <SideBar
      v-if="open"
      title="Editar Local de Armazenamento"
      @hide="cancelar"
      :active="modalEditar"
      textSucces="Atualizar"
      icon="edit"
      size="small"
      :disabled="!$atividades.permissoes.includes(15)"
      :item="item"
      @excluir="excluir()"
      @status="mudarStatus()"
      @success="submitForm()"
    >
      <div slot="body" class="mb-4">
        <div :id="'loadingEditar' + item.id" class="vs-con-loading__container">
          <vs-row class="mt-2" vs-type="flex" vs-w="12">
            <vs-col vs-w="12">
              <vs-input
                class="inputx w-full"
                name="nome"
                v-validate="'required'"
                label-placeholder="Nome"
                v-model="item.nome"
              />
              <span class="text-danger text-sm" v-show="errors.has('nome')">{{
                $validators.empty
              }}</span>
            </vs-col>
          </vs-row>
        </div>
      </div>
    </SideBar>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalEditar: false,
      open: false,
      originalData: {}
    };
  },
  methods: {
    async openModal() {
      this._beforeEditingCache = Object.assign({}, this.item);
      this.originalData = await this.item;
      this.open = await true;
      this.modalEditar = await true;
    },
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editar();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async editar() {
      await this.$vs.loading({
        container: "#loadingEditar" + this.item.id,
        scale: 0.6
      });
      try {
        const res = await this.$http.put(
          `armazenamento/` + this.item.id,
          this.item
        );
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "pco",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição do local de armazenamento N°" + res.id
        };
        await this.$logger(logData);
        this.$vs.notify({
          title: "Sucesso",
          text: "Operação realizada com sucesso!",
          color: "success"
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.modalEditar = await false;
        await this.$vs.loading.close(
          "#loadingEditar" + this.item.id + " > .con-vs-loading"
        );
        await this.$emit('update')
      }
    },
    async excluir() {
      try {
        await this.$http.delete(
          `armazenamento/` + this.item.id
        );
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "excluir",
        //   setor: "pco",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Exclusão do local de armazenamento N°" + this.item.id
        // };
        // await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.modalEditar = await false;
        await this.$emit('update')
      }
    },
    async mudarStatus() {
      try {
        const res = await this.$http.put(
          `armazenamento/` + this.item.id,
          {lixeira: !this.item.lixeira}
        );
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "update",
          setor: "pco",
          ip: window.localStorage.getItem("ip"),
          texto: "Mudança do status do local de armazenamento N°" + res.id
        };
        await this.$logger(logData);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.modalEditar = await false;
        await this.$emit('update')
      }
    },
    async cancelar() {
      Object.assign(this.item, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditar = false;
    }
  },
  components: {
    ...components
  }
};
</script>
